
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
:root {
  --text-primary: #fff;
  --text-secondary: #90919c;
  --text-tertiary: #696b77;
  --text-inverted: #000;
  --text-positive: #63c966;
  --text-positive-pass: #54ab57;
  --text-negative: #ff0014;
  --text-light-secondary: #626c77;
  --background-primary: #fff;
  --controls-primary-light: #ff404f;
  --controls-primary-active: #ff0014;
  --controls-primary-dark: #cc1414;
  --controls-tertiary-light: rgb(138 140 153 / 45%);
  --controls-tertiary-active: rgb(138 140 153 / 25%);
  --controls-tertiary-dark: rgb(138 140 153 / 10%);
  --controls-inactive: rgb(138 140 153 / 20%);
  --controls-alternative: rgb(138 140 153 / 35%);
  --controls-active-tabbar: #fff;
  --controls-inactive-tabbar: #60626b;
  --accent-active: #45b6fc;
  --accent-positive: #77c979;
  --accent-warning: #fad67d;
  --accent-negative: #ff5b68;
  --accent-active-inverted: #007cff;
  --accent-positive-inverted: #63c966;
  --accent-warning-inverted: #fac031;
  --accent-negative-inverted: #ff0014;
  --accent-marker-yellow: #ffdf34;
  --accent-marker-pink: #c446c7;
  --accent-marker-background-green: rgb(49 229 54 / 30%);
  --accent-marker-background-yellow: rgb(255 223 52 / 50%);
  --accent-marker-background-pink: rgb(196 70 199 / 45%);
  --accent-marker-background-red: rgb(255 0 20 / 40%);
  --accent-marker-background-blue: rgb(0 124 255 / 39%);
}
