
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  width: 100%;
  padding: 24px 0;
}

.container {
  display: grid;
  width: 100%;
  align-items: center;
  column-gap: 12px;
  grid-template-columns: minmax(178px, 1fr) 1fr 1fr;
}

.logo {
  width: 178px;
  height: auto;
  margin-right: 20px;
}