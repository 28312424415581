
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.app {
  width: 100%;
  height: 100%;
  padding: 0 36px 0 40px;
  margin: 0 auto;
  background-color: var(--background-primary);
}

.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
}

.root {
  display: flex;
  width: 100%;
  margin: 0 auto;
}