
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
@font-face {
  font-display: swap;
  font-family: Aeroport;
  font-style: normal;
  font-weight: 400;
  src: url('../../../public/fonts/Aeroport/Aeroport.woff2') format('woff2'),
    url('../../../public/fonts/Aeroport/Aeroport.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Aeroport;
  font-style: normal;
  font-weight: 500;
  src: url('../../../public/fonts/Aeroport/Aeroport-Medium.woff2') format('woff2'),
    url('../../../public/fonts/Aeroport/Aeroport-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Aeroport;
  font-style: normal;
  font-weight: 700;
  src: url('../../../public/fonts/Aeroport/Aeroport-Bold.woff2') format('woff2'),
    url('../../../public/fonts/Aeroport/Aeroport-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Aeroport;
  font-style: normal;
  font-weight: 500;
  src: url('../../../public/fonts/Aeroport/Aeroport-Light.woff2') format('woff2'),
    url('../../../public/fonts/Aeroport/Aeroport-Light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Aeroport;
  font-style: italic;
  font-weight: 500;
  src: url('../../../public/fonts/Aeroport/Aeroport-Italic.woff2') format('woff2'),
    url('../../../public/fonts/Aeroport/Aeroport-Italic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Aeroport;
  font-style: italic;
  font-weight: 700;
  src: url('../../../public/fonts/Aeroport/Aeroport-BoldItalic.woff2') format('woff2'),
    url('../../../public/fonts/Aeroport/Aeroport-BoldItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Aeroport;
  font-style: italic;
  font-weight: 500;
  src: url('../../../public/fonts/Aeroport/Aeroport-LightItalic.woff2') format('woff2'),
    url('../../../public/fonts/Aeroport/Aeroport-LightItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Aeroport;
  font-style: italic;
  font-weight: 500;
  src: url('../../../public/fonts/Aeroport/Aeroport-MediumItalic.woff2') format('woff2'),
    url('../../../public/fonts/Aeroport/Aeroport-MediumItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: AeroportMono;
  font-style: italic;
  font-weight: 500;
  src: url('../../../public/fonts/Aeroport/AeroportMono.woff2') format('woff2'),
    url('../../../public/fonts/Aeroport/AeroportMono.woff') format('woff');
}
