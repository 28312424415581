@mixin desktopL {
  @media (max-width: $desktopL-width) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: $laptop-width) {
    @content;
  }
}
